@use "./config/" as *;
@forward "./main-scss/";
@forward "./listing/common";
.waf-listing {
    --_listing-line: 1;
    --_listing-title: 16;
    .article {
        &-item {
            @extend %mb-4;
        }
    }
    .article-content {
        position: unset;
    }
    .reaction-section {
        position: unset;
    }
    .views-section {
        @extend %d-none;
    }
    .item-type-icon {
        position: absolute;
        top: var(--space-3);
        right: var(--space-3);
        backdrop-filter: blur(2rem);
        width: max-content;
        height: 3rem;
        aspect-ratio: unset;
        @extend %neutral-50;
        @extend %neutral-900-bg-6;
        @extend %p-1-2;
        @extend %flex-c-c;
        @extend %gap-1;
        @extend %half-radius;
        @extend %font-14-pb;
        &::before {
            @include icon(image)
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        --_listing-line: 2;
        --_listing-title: 16;
        @include listing-card(overlay);
        .article {
            &-list {
                grid-template-columns: repeat(4, 1fr);
                @include listing-card("overlay")
            }
            &-content {
                position: absolute;
                bottom: -100%;
                left: 0;
                transition: 300ms bottom cubic-bezier(0.175, 0.885, 0.32, 1.33);
                .views-section {
                    position: fixed;
                }
            }
            &-thumbnail {
                img {
                    transition: 300ms transform cubic-bezier(0.175, 0.885, 0.32, 1.66);
                    transform: scale(1.1);
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
            &-item {
                height: 100%;
                margin: 0;
                transform: scale(1);
                &:nth-child(10n+2),
                &:nth-child(10n+8) {
                    grid-column: span 2;
                    grid-row: span 2;
                }
                &:hover {
                    .article-content {
                        bottom: 0;
                    }
                }
            }
        }
    }
}